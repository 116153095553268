/* Icons */
@font-face {
	font-weight: normal;
	font-style: normal;
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?i64fx9');
	src:url('../fonts/icomoon/icomoon.eot?#iefixi64fx9') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff?i64fx9') format('woff'),
		url('../fonts/icomoon/icomoon.ttf?i64fx9') format('truetype'),
		url('../fonts/icomoon/icomoon.svg?i64fx9#icomoon') format('svg');
}

.icon-close {
	z-index: 100;
	display: block;
	overflow: hidden;
	width: 3em;
	height: 3em;
	text-align: center;
	line-height: 3;
	cursor: pointer;
}

.icon:before {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	font-family: 'icomoon';
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-camera:before {
	content: "\e017";
}

.icon-server:before {
	content: "\e022";
}

.icon-heart:before {
	content: "\e024";
}

.icon-zoom-in:before {
	content: "\e037";
}

.icon-microphone:before {
	content: "\e048";
}

.icon-cloud:before {
	content: "\e066";
}

.icon-user:before {
	content: "\e074";
}

.icon-briefcase:before {
	content: "\e075";
}

.icon-globe:before {
	content: "\e078";
}

.icon-cog::before {
	content: "\e600";
}

.icon-close::before {
	content: "\e601";
}

.icon-play::before {
	content: "\e602";
}

.icon-pause::before {
	content: "\e603";
}

.icon-close {
	position: absolute;
	right: 0px;
	-webkit-text-stroke: 1px #fff;
    color: #000000;
	font-size: 16px;
}

.icon-close:hover {
	color: #000;
}

.move-icon{ width:36px; height:48px;
	display:flex; align-items:center; justify-content:center; font-size:20px;
	-webkit-text-stroke: 1px #fff;
    color: #000000;}

	.move-icon:hover{color: black;}

/* Styles for dummy content */









