// Here you can add other styles

// loader

.hidden {
    visibility: hidden;
}

.loader-overlay {
    position: absolute;
    width: 100%;
    z-index: 500000;
    top: 0;
}

.loader {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #e4e5e6;
}

.loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: red;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 70%;
    }

    80% {
        left: 50%;
    }

    95% {
        left: 120%;
    }

    to {
        left: 100%;
    }
}

.verification-container {
    .verification-text {
        padding: 10px 0;
        margin: 0;
        color: #555454;
        font-size: 15px;
        font-weight: 500;

        .resend-btn {
            color: #0097cc;
            cursor: pointer;
        }
    }
}

.has-error {
    padding: 0;
    margin: 0;
    color: red;
}

.link {
    cursor: pointer;

}

.main {
    .container-fluid {
        padding: 0 5px;
    }
}

.header-fixed .app-header {
    z-index: 11 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #ffffff00;
}

.mat-form-field-wrapper .mat-form-field-underline {
    width: 0%;
}

title {
    display: block;
    font-size: 200%;
    font-weight: bold;
}

.breadcrumb {
    margin-bottom: 0.5rem;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
}

.clear-all {
    display: none !important;
}

.traffic-light-content {
    height: 35px;
}