// If you want to override variables do it here
@import "variables";
// Import styles
// @import "@coreui/coreui/scss/coreui";
// If you want to add something do it here
@import "radios";
@import "custom";


.navbar-toggler{background-image:url(../assets/img/hamburger.svg) !important; background-size:25px 25px; width:25px; height:25px; background-repeat:no-repeat; background-position:center center}

  .navbar-toggler span{ display:none !important }

.text-right{
    text-align: right;
}

h4 {
    font-size: 1.3125rem;
}

h5 {
  font-size: 1.09375rem;
}

h6 {
  font-size: .875rem;
}

body {
  background-color: $navbg;
  font-family: 'Lexend', sans-serif;
  font-weight:300;
  font-size: 14px;
}

button{ font-weight:300 !important}

a{font-weight:300 !important; color:$navactive; text-decoration: none;}

.btn-facebook{
  color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
    display: block;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;

    &:hover{
      color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
    }

    &:focus{
      color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
    }

    &:active{
      color: #fff !important;
    background-color: #30497c !important;
    border-color: #2d4373 !important;
    }
}

.c-account{
  color: #fff;
  background-color: #03c795;
  border-color: #03c795;
  display: block;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;

    &:hover{
      color: #fff;
    background-color: #02a179;
    border-color: #02956f;
    }

    &:focus{
      color: #fff;
    background-color: #02a179;
    border-color: #02956f;
    }

    &:active{
      color: #fff;
    background-color: #02a179;
    border-color: #02956f;
    }
}

.border-right-0{
  border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    i{
      font-size: 14px;
    }
}

.register-form{ 

  h1{ font-size: 35px;}
  
  input{
  font-size: 14px;
}

}

.login-banner{
  background-image: url(../assets/img/DB-login.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .db-pairasuit{ 
    height:150px !important; 
    float:right
  }

  

  .logo{
    width: 100%;
    float:left;
    display: block;
    padding-left: 20%;
    padding-right: 20%;
  }

  p{ 
    float:left; 
    text-align:center; 
    width:100%; 
    font-size:18px; 
    padding-left:20px; 
    padding-right:20px; 
    margin-top:20px;
  }
}

.bg-light-gray{background-color: $bggray !important;}

.traffic-light-content .popover-content.popover-body{
  display: flex !important;
  padding: 5px 15px !important;
}

label{
  font-family: Lexend, sans-serif !important;
  font-size: 14px !important;
}

.font-weight-bold{
  font-weight: bold;
}

.form-bg{
  
  .form-design{ 
    padding:2rem !important;

    .form-group{
      margin-bottom:0px;

      label{ 
        margin-bottom:0px;
      }

      input{ 
        background-color:transparent !important; 
        padding-left:0px !important; 
        padding-right:0px !important;  
        border: none !important;
        border-bottom: 1px solid $dkgray !important; 
        border-radius:0px !important;
        font-weight: 300 !important;
   
    &:focus{ 
      outline: none;
      box-shadow:unset !important;
    }
      }

      select{ 
        background-color:transparent !important; 
        padding-left:0px !important; 
        padding-right:0px !important;  
        border: none !important;
        border-bottom: 1px solid $dkgray !important; 
        border-radius:0px !important;
        font-weight: 300 !important;
  
    &:focus{ 
      outline: none;
      box-shadow:unset !important;
    }
      }

      .line {
        height: 1px;
        width: 0px;
        position: absolute;
        background-color: darkgrey;
        display: inline-block;
        transition: .3s width ease-in-out;
        position: relative;
        top: -18px;
      }
    }

    h3{ 
      font-size:24px; 
      margin-bottom:5px !important
    }
    p{ 
      font-weight:300 !important;
    }

  }
}


.setting-form{
  .form-group{
    margin-bottom:0px;

    label{ 
      margin-bottom:0px;
    }

    input{ 
      background-color:transparent !important; 
      padding-left:0px !important; 
      padding-right:0px !important;  
      border: none !important;
      border-bottom: 1px solid $dkgray !important; 
      border-radius:0px !important;
      font-weight: 300 !important;
      font-size: 14px;
      color:#5c6873;
 
  &:focus{ 
    outline: none;
    box-shadow:unset !important;
  }
    }

    select{ 
      background-color:transparent !important; 
      padding-left:0px !important; 
      padding-right:0px !important;  
      border: none !important;
      border-bottom: 1px solid $dkgray !important; 
      border-radius:0px !important;
      font-weight: 300 !important;

  &:focus{ 
    outline: none;
    box-shadow:unset !important;
  }
    }

    .line {
      height: 1px;
      width: 0px;
      position: absolute;
      background-color: darkgrey;
      display: inline-block;
      transition: .3s width ease-in-out;
      position: relative;
      top: -14px;
    }
  }
}

.read-email{ 
  background-color:transparent !important; 
  padding-left:0px !important; 
  padding-right:0px !important;  
  border: none !important;
  border-bottom: 1px solid $dkgray !important; 
  border-radius:0px !important;
  font-weight: 300 !important;
  color:#5c6873;
  font-size: 14px;
}

.form-group .read-email{ 
  padding-left:0px !important; 
}


.form-body .login-footer p{ color:white !important; font-size: 14px;}
.form-body .login-footer img{ filter:invert(1)}

.setting-form .form-group input:focus+.line, select:focus+.line {
  width: 100%;
  background-color: $navbg;
}

.form-bg .form-design input:focus+.line, select:focus+.line {
  width: 100%;
  background-color: $navbg;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
   
   filter: grayscale(100%) brightness(110%);
}


.form-body {
 background-image: url(../assets/img/Red.jpg);
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  background-blend-mode: multiply;

  .main {
    padding-bottom: 0px;
  }

  .header-wrapper {
    .moco-logo {
      img {
        width: 175px;
        height: auto;
      }
    }
  }
}

.has-error {
  color: $dkred !important;
}

.aside-menu {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.app,
app-dashboard,
app-root {
  @media #{$mq-767-land} {
    overflow-x: hidden;
  }

  @media #{$mq-768-up} {
    overflow-x: hidden;
  }
}

.form-body .main-div {
  @media #{$mq-360-down} {
    display: contents !important;
  }
}



.sidebar-fixed .app-header+.app-body .main {
  height: calc(100% - 55px);
}

.sidebar-lg-show {
  .main-sidebar {
    .dutch-breadcrumb {
      @media #{$mq-992-down} {
        max-width: calc(100% - 240px) !important;
      }
    }
  }

  .app-header {
    @media #{$mq-992-down} {
      margin-left: 240px;
      width: calc(100% - 240px) !important;
    }
  }
}

.brand-minimized .navbar-toggler:nth-child(3) {
  pointer-events: none;
}


.video-player-panel {
  background: white;
  padding: 5px 15px 10px 15px;
  box-shadow: 0px 2px 10px #ccc;
}

.video-player-panel .mat-dialog-container {
  background: transparent !important;
  box-shadow: unset
}

.video-player-panel .drag-btn1 {
  background-color: #e8e8e8;
  border-radius: 40px;
  width: 35px !important;
  height: 35px !important;
  color: #777777 !important;
  -webkit-text-stroke: 1px #e8e8e8 !important;
  margin-top: 0px;
}

.video-player-panel .close-btn1 {
  background-color: #e13d44;
  border-radius: 40px;
  width: 35px !important;
  height: 35px !important;
  color: white !important;
  -webkit-text-stroke: 1px #e13d44 !important;
  margin-top: 0px;
}

.close-btn1:focus {
  outline: none !important;
}

.drag-btn1:focus {
  outline: none !important;
}

.heding-middle {
  //     display: flex;
  // align-items: center;
  padding-top: 4px;
  height: 100%;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}

.heding-middle h5 {
  font-size: 16px;
}

.heding-middle p {
  font-size: .9em;
}


.main-sidebar .sidebar .nav-link.active{
  font-weight: 500 !important;
}

.main-sidebar .sidebar .nav-link:hover{
  font-weight: 500 !important;
}

.nav-item .nav-link>.img-avatar{
  height: 26px !important; 
  object-fit: cover;
  width:26px !important
}

.nav-item .icon-bell{
  font-size: 24px;
    padding-top: 13px;
    line-height: 38px;
    color:#73818f;
}

.sidebar {
  .nav-logo {
    @media #{$mq-991-up} {
      width: 240px !important;
      padding-top: 1.5pc;
      background: $navbg;
      position: relative;
      padding-bottom: 2.8pc;
    }
  }
}

.nav-logo {
  width: 55px;
  height: 55px;
  justify-content: left;
  padding-left: 10px;
  display: inline-block;
  padding-top: 25px;
  padding-bottom: .3359375rem;
  margin-right: 0rem;
  z-index: 1111;
  position: fixed;
  top: 0;

  @media #{$mq-991-up} {
    pointer-events: none;
  }
}

.app-header {
  border-bottom: unset;
  height: 75px;
 /* margin-top: 10px;
  margin-left: 10px;*/
  width: 100% !important;
  background-color: $bggray;
 /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;*/

  @media #{$mq-991-up} {
    margin-left: 0px;
    width: 100% !important;
    position: fixed;
    height: 55px;
    margin-top: 0px;
    border-radius: 0px;
    border-bottom: 2px solid $navbg;
  }

  .navbar-toggler {

    &:focus,
    &:hover {
      outline: 0px;
    }

    &:nth-child(5) {
      display: none !important;
    }

    &:nth-child(6) {
      @media #{$mq-991-up} {
        display: none !important;
      }

      @media #{$mq-768-up} {
        display: none !important;
      }
    }

    &:nth-child(1) {
      @media #{$mq-768-up} {
        padding-left: 10px !important;
      }

      @media #{$mq-991-up} {
        padding-left: 10px !important;
      }
    }

    &:nth-child(3) {
      padding-left: 6px;
    }
  }

  .navbar-brand {
    @media #{$mq-991-up} {
      display: none;
    }
  }

  .nav-item {
    min-width: 60px;
  }
}

.main {
  padding-bottom: 50px;

  .container-fluid {
    padding: 0 15px;
  }
}

.main-sidebar {
  @media #{$mq-991-up} {
    margin-bottom: 0px !important;
  }

  .sidebar {
    background: $navbg;
    padding-top: 0pc;

    @media #{$mq-992-down} {
      width: 240px;
    }

    @media #{$mq-991-up} {
      width: 240px;
      margin-top: 0px;
    }

    @media #{$mq-767-land} {
      margin-top: 2pc;
      height: calc(100vh);
    }

    .sidebar-nav,
    .sidebar-scroll {
      width: 240px;

      @media #{$mq-991-up} {
        padding-top: 0pc;
      }
    }





    .nav {
      width: 240px;
      padding-left: 5px;
      display: inline;
    }

    .nav-link {
      letter-spacing: .5px;
      padding: 1rem 1rem;
      margin-left: 0px;
      margin-bottom: 5px;
      margin-top: 5px;
      font-weight:300;
    }

    .nav-link:hover {
      background: $navactive;
      color: $white;
      font-weight:300 !important;

      .nav-icon {
        color: $white;
      }
    }

    .nav-link.active {
      background: $navactive;
      color: $white;
      font-weight: bold;
      position: relative;
      border-left: 4px solid $dkred;
      font-weight:300 !important;

      @media #{$mq-991-up} {
        border-radius: 0px;
      }

      .nav-icon {
        color: $white;
        font-weight: 600;
      }
    }

   /* .nav-link.active:before {
      content: "";
      width: 10px;
      height: 10px;
      margin-top: -10.2px;
      transform: rotate(0deg) scale(1.04);
      background-size: 100%;
      background-image: url('../assets/img/image2vector.svg');
      position: absolute;
      top: 0;
      right: 0;

      @media #{$mq-991-up} {
        display: none;
      }
    }

    .nav-link.active:after {
      content: "";
      width: 10px;
      height: 10px;
      margin-top: 37px;
      transform: rotate(0deg) scale(1.04);
      background-size: 100%;
      background-image: url('../assets/img/image3vector.svg');
      position: absolute;
      top: 0;
      right: 0;

      @media #{$mq-991-up} {
        display: none;
      }
    } */

    .nav-icon {
      color: $white;
      margin: 0 1rem 0 0;
      font-size: 14px;
    }

    .badge-info {
      display: none;
    }

    .sidebar-minimizer {
      background-color: $navbg;
    }

    .sidebar-minimizer:after {
      content: "\e605";
      top: 1pc;
      right: 0;
      width: 40px;
      height: auto;
      position: absolute;
      font-family: simple-line-icons;
      speak-as: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      transition: .3s;
      font-size: 18px;
    }

    .sidebar-minimizer:before {
      display: none;
    }
  }

  .dutch-breadcrumb {
    min-height: 82vh;
    background: $bggray;
    max-width: 100% !important;
   /* border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;*/
    padding-left: 0px;

    @media #{$mq-992-down} {
      margin-left: 0px;
    }

    @media #{$mq-991-up} {
      max-width: 100% !important;
      margin-top: 0px;
      border-radius: 0px;
      min-height: 86vh;
    }

    .breadcrumb {
      background-color: $bggray;
      margin-left: 15px;
      margin-right: 15px;
      border-bottom: unset;
      display: block;

      .breadcrumb-item.active {
        color: #303030;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .breadcrumb-menu {
        .btn {
          color: $dkgray;
          font-weight: 500;
          padding: 0 5px;
        }

        .btn [class*=" icon-"],
        .btn [class^=icon-] {
          font-weight: 600;
        }

        span {
          color: $dkgray;
        }
      }
    }
  }
}

.sidebar-minimized .sidebar .sidebar-minimizer:after {
  transform: rotate(-180deg);
  width: 100%;
  text-align: center;
  top: 1pc;
}

html:not([dir=rtl]) .sidebar {
  margin-left: -240px;
}

.logout-btn {
  i {
    color: $power;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
  }

  i:hover {
    color: $dkred;
  }
}

.analysis-wrap {
  background-color: $trans;
  border: 0px !important
}

.next-btn {
  background-color: $navactive;
  color: $white;
  box-shadow: 0px 10px 10px $blueshadow;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer !important;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    color: $white;
    background-color: $navbg;
  }

  &:active {
    color: $white !important;
    background-color: $navbg !important;
  }

  &:focus-visible{
    color: $white !important;
    background-color: $navbg !important;
  }

  @media #{$mq-575-up} {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.history-btn {
  background-color: $nextbtn;
  color: $white !important;
  padding: 8px 20px;
  border-radius: 40px;

  &:hover {
    color: $black !important;
    background-color: $bdcolor;
  }

  &:active {
    color: $black !important;
    background-color: $bdcolor !important;
  }
}

.view-btn {
  padding: 7px .594rem;
  border: 1px solid $navactive;
  border-radius: 3px;
  text-align: center;
  color: $navactive !important;

  &:hover {
    color: $white !important;
    background-color: $navactive;
  }

  &:active {
    color: $white !important;
    background-color: $navactive !important;
  }
}

.exit-btn{
  background-color: $dkred;
  color: $white;
  box-shadow: 0px 10px 10px $btnshadow;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    color: $white;
    background-color: $btnhovr;
  }

  &:active {
    color: $white !important;
    background-color: $btnhovr !important;
  }

  @media #{$mq-575-up} {
    padding-left: 25px;
    padding-right: 25px;
  }
}


.previous {
  background-color: $nextbtn;
  color: $white;
  box-shadow: 0px 10px 10px $nextbtnshadow;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    color: $black;
    background-color: $bdcolor;
  }

  &:active {
    color: $black !important;
    background-color: $bdcolor !important;
  }

  &:focus {
    color: $black !important;
    background-color: $bdcolor !important;
  }

  @media #{$mq-575-up} {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.add-btn {
  background-color: $navactive;
  color: $white !important;
  box-shadow: 0px 10px 10px $blueshadow;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer !important;
  font-size: 14px;

  &:hover {
    color: $white !important;
    background-color: $navbg;
  }

  &:active {
    color: $white !important;
    background-color: $navbg !important;
  }

  @media #{$mq-575-up} {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.add-btn1 {
  background-color: $navbg;
  color: $white !important;
  box-shadow: 0px 10px 10px $blueshadow;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  cursor: pointer !important;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    color: $white !important;
    background-color: $navactive !important;
  }

  &:active {
    color: $white !important;
    background-color: $navactive !important;
  }

  @media #{$mq-575-up} {
    padding-left: 25px;
    padding-right: 25px;
  }
}





.graph-displayer {
  font-size: 20px;
  z-index: 1021;
  position: fixed;
  background-image: linear-gradient(to right, #ee3539 , #3a448c);
  right: -103px;
  top: 25%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px 0px 0px 3px;
  font-size: 14px;
  padding: 4.5px 3px;
  transition: all 0.5s ease;

  a {
    color: $white;
    display: block;
    padding: 7px 16px;
    text-decoration: none;
  }
}

.graph-displayer:hover {
  right: 0px;
}

.live-graph-container {
  padding: 15px;
  z-index: 1021;
  position: fixed;
  background: $white;
  right: 15px;
  width: 350px;
  top: 5%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  .fa-arrows {
    -webkit-text-stroke: 1px $white;
    color: $black;
  }

  .fa-times {
    -webkit-text-stroke: 5px $white;
    color: $black;
    font-size: 32px;
    line-height: 26px;
    margin-left: 10px;
  }
}

.tooltip {
  animation: city 1s ease-in-out;
}

.tooltip-inner {
  box-shadow: 0px 5px 10px rgba(3, 199, 149, .2)
}

@keyframes city {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.delete {
  width: 35px;
  height: 35px;
  margin: auto;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dkred !important;
  font-size: 18px;
  border: 1px solid $dkred;
  cursor: pointer !important;
  float: right;
  text-decoration: none;

  &:hover {
    color: $white !important;
    background-color: $dkred;
    box-shadow: 0px 10px 15px $btnshadow;
    text-decoration: none;
  }

  &:active {
    color: $white !important;
    background-color: $dkred !important;
    box-shadow: 0px 10px 15px $btnshadow !important;
    text-decoration: none;
  }
}

.popover {
  z-index: 11 !important
}

html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fcfdfd;
  @media #{$mq-992-down} {
    margin-left: 240px;
  }
}

.table-shadow {
  box-shadow: 0px 0px 20px $nextbtnshadow;
}

.table-bordered td,
th {
  border-bottom: 1px solid $inputbg !important;
  line-height: 16px;
  border-left:0px !important;
  border-right:0px !important;
  border-top:0px !important;
}

.table thead th {
  font-weight: 500;
  white-space: nowrap !important;
  background-color: $bggray !important;
}

.tooltip {
  opacity: 1 !important
}

.tooltip-top {
  position: absolute;
  top: -80px !important;
  z-index: 9;
}

.position-tooltip {
  position: relative;
  z-index: 9;
  margin-left: 5px;
  margin-top: 2px
}

.tooltip .tooltip-inner {
  background-color: $white !important;
  color: $black;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: $white !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: $green !important;
}

.badge-success {
  color: #fff;
  background-color: #03c795;
}

.location {
  .tooltip-bottom {
    position: absolute;
    top: 35px !important;
    left: 30% !important;
    z-index: 9;
  }
}

.tooltip-bottom {
  position: absolute;
  /* top: 35px !important;
  left:30% !important; */
  z-index: 9;
}

.bs-tooltip-bottom .tooltip-inner {
  background-color: $green !important;
  color: $white !important;
}

.cdk-global-overlay-wrapper {
  background-color: $modal-back;
}

.center-traffic {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-width: 0px !important;
  height: 46px;
}

.btn:focus {
  box-shadow: unset !important;
}

.form-control {
  padding: .375rem !important;

  &:focus {
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, .1) !important;
    border-color: unset !important;
  }
}

html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main {
  @media #{$mq-767-land} {
    margin-left: 240px;
  }
}

@media #{$mq-992-down} {
  .header-fixed .app-body {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sidebar-minimized .sidebar .nav-link .nav-icon {
    width: 100%;
  }

  .sidebar-fixed .app-header+.app-body .sidebar {
    height: calc(100vh - 85px);
  }

  .header-fixed .app-header {
    position: relative !important;
    background-color: $navbg;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
}

@media #{$mq-768-up} {

  html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed .app-footer,
  html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed .main,
  html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer,
  html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main {
    margin-left: 240px;
  }
}

@media (max-width: 575.98px) {
  .sidebar-show .main:before {
    position: fixed;
    top: inherit;
    height: calc(100% - 55px);
  }
}

.submit-btn {
  margin-bottom: 15px;
  background: $dkred;
  border-radius: 3px;
  border: 1px solid $dkred;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: 0 6px 12px $btnshadow;
  padding: 10px 0;
  color: $white;
  width: 100%;
  font-size: 14px;

  &:hover {
    color: $white;
    background-color: $btnhovr;
    border: 1px solid $btnhovr;
  }

  &:focus {
    box-shadow: 0 6px 12px $btnshadow;
  }
}

.add-btn {
  background: $navactive;
  border: 1px solid $navactive;
  text-transform: uppercase;
  /*  font-weight: bold;
    padding: 10px 25px; */
  box-shadow: 0px 10px 25px $blueshadow;
  color: $white;

  &:hover {
    background: $navbg;
    color: $white;
    border-color: $navbg;
  }

  &:focus {
    box-shadow: 0px 10px 25px $grshadow;
  }
}

.text {
  app-your-goal {
    width: 63%
  }

  input {
    padding: 0px 5px !important;
    width: 100% !important;
    font-size: 14px;
    font-style: italic;
    color: black;
    font-weight: 500;
    height: 21px;
    border: 0px;
    margin-top: 5px;
  }

  .form-group {
    margin-bottom: 0px !important;
  }

  .form-control:focus {
    box-shadow: unset !important;
    border-color: unset !important;
    color: $black !important;
  }
}

.tag-wrapper {
  .ng2-tag-input {
    border: 0px solid transparent;
    padding: .157rem 0px !important;
    border-bottom: 1px solid $dkgray !important;
    border-radius: 0px;
  }

  .ng2-tag-input__text-input {
    height: 28px !important;
    vertical-align: middle !important;
    padding: 0px !important;
    font-weight: 400;
  }

  .ng2-tag-input__text-input::-webkit-input-placeholder {
    color: $plcolor !important;
    font-weight: 400;
  }

  .ng-trigger {
    border-radius: 3px;
    margin-left:0px;
    text-transform: capitalize;
    line-height: 25px;
    transition: none;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
  }

  tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
    background: #efefef !important;
    color: initial;
    box-shadow: unset !important;
  }

  delete-icon:hover {
    transform: none !important;
  }

  delete-icon {
    transition: none;
    width: 14px !important;

    svg {
      vertical-align: middle !important;
      height: auto !important;
      width: 12px;
    }

    path {
      fill: $dkgray !important;
    }
  }
}

.round .selected-list .c-btn {
  border-radius: 0px !important;
  background-color: transparent;
}

.selected-list {
  .c-btn {
    border-bottom: 1px solid $dkgray !important;
    color: $plcolor !important;
    font-weight: 300;
    padding: .532rem 6px !important;
    min-height: unset;
    outline: none;
    border:0px;
    padding-left: 0px !important;
  }

  .c-angle-down {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    top: 45% !important;
    right: 6px !important;

    svg {
      fill: $plcolor !important;
    }
  }
  
  .c-btn.disabled {
    background: transparent;
  color:$ltpurple !important
}
  .c-angle-up {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45% !important;
    right: 6px !important;

    svg {
      fill: $plcolor !important;
    }
  }
}

.single-select-mode {
  border: 1px solid $inputbg;
  border-radius: 3px;
  box-shadow: unset;
  background-color: $inputbg;

  .pure-checkbox {
    padding: 4px;
    border-bottom: 1px solid $inputbg;

    &:first-child {
      padding-top: 0px;
    }

    input[type=checkbox]+label {
      color: $plcolor !important;
      font-weight: 600;
      text-transform: capitalize;

      &:before {
        border-color: $dkred;
      }

      &:after {
        border-color: $dkred;
      }
    }
  }
}

.modal-open {
  .modal {
    display: flex !important;

    .modal-dialog {
      align-self: center;

      .modal-content {
        border: 0px;

        .modal-header {
          border-bottom: 0px;
          color: $dkblack;

          h4 {
            text-transform: uppercase;
          }

          button {
            &:focus {
              outline: 0px;
            }

            span {
              font-size: 30px;
            }
          }
        }

        .modal-body {
          color: $dkgray;
          font-weight: 600;
          font-size: 16px;

          p {
            b {
              color: $dkred;
            }
          }
        }

        .modal-footer {
          border-top: 0px;

          .btn-secondary {
            background: $nextbtn;
            color: $white;
            padding-left: 22px;
            padding-right: 22px;
            border-radius: 50px;
            box-shadow: 0 10px 10px $nextbtnshadow;
            text-transform: uppercase;

            &:hover {
              color: $black;
              background-color: $ltgrayshdw;
            }
          }

          .btn-primary {
            background: $dkred;
            color: $white;
            padding-left: 22px;
            padding-right: 22px;
            border-radius: 50px;
            box-shadow: 0 10px 10px $btnshadow;
            text-transform: uppercase;
            border: 0px;

            &:hover {
              color: $white;
              background-color: $btnhovr;
            }
          }
        }
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 1050;
  /* background: rgba(0, 0, 0, .32); */
}

@media #{$mq-575-up} {
  .cdk-overlay-pane {
    width: 95vw !important
  }
}

.custom-close {
  position: absolute;
  margin: unset !important;
  top: 0px;
  right: 0px;

  span {
    font-size: 25px !important;
  }
}

.single-select-mode {
  .pure-checkbox {
    padding: 7px !important;
    border-bottom: 1px solid #efefef;

    input[type=checkbox]+label {
      position: relative;
      padding-left: 25px !important;
    }
  }

  &:first-child {
    padding: unset;
  }
}

.app-header .navbar-brand-full {
  display: none !important;
}

@media (min-width: 992px) {

  html:not([dir=rtl]) .brand-minimized .main-sidebar .sidebar .sidebar-nav,
  html:not([dir=rtl]) .brand-minimized .main-sidebar .sidebar .sidebar-scroll {
    width: 50px;
  }

  html:not([dir=rtl]) .brand-minimized .navbar-brand-full {
    display: none;
  }

  .brand-minimized .app-header .navbar-brand .navbar-brand-minimized {
    display: block;
    margin-right: 50px;
  }

  .brand-minimized .app-header .navbar-brand,
  .app-header .navbar-brand {
    width: 0px;
  }

  html:not([dir=rtl]) .brand-minimized .main-sidebar .dutch-breadcrumb {
    max-width: calc(100% - 60px) !important;
  }

  html:not([dir=rtl]) .brand-minimized .app-header {
    width: calc(100% - 60px) !important;
    margin-left: 50px;
  }

  .brand-minimized .sidebar .nav-item:hover {
    width: 240px !important;
    overflow: visible;
  }

  .brand-minimized .main-sidebar .sidebar .nav {
    width: 50px;
    padding-left: 0px;
    display: flex;
  }

  .brand-minimized .sidebar .nav-link:hover {
    width: 240px !important;
  }

  .brand-minimized .sidebar .nav-item:hover>.nav-link {
    background: $navbg !important;
  }

  .brand-minimized .sidebar .nav-item:hover .nav-icon {
    color: $white !important;
  }

  .sidebar-minimized .sidebar .nav-link:hover .badge {
    display: none;
  }

  .brand-minimized .sidebar .nav-link {
    padding: .5rem 0rem;
    margin-left: 0px;
  }

  .brand-minimized .sidebar .nav>.nav-dropdown:hover {
    background-color: $navbg;
  }

  .brand-minimized .sidebar .nav-link .nav-icon {
    width: 50px !important;
  }

  .brand-minimized .main-sidebar .sidebar .nav-link.active:before,
  .brand-minimized .main-sidebar .sidebar .nav-link.active:after {
    display: none;
  }

  .brand-minimized .sidebar .nav-item:hover>.nav-link {
    color: $white !important;
  }

  .brand-minimized .main-sidebar .sidebar .nav-link:hover {
    background: $navbg;
    color: $white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .brand-minimized .sidebar .nav>.nav-dropdown>.nav-dropdown-items {
    background: $navbg !important;
  }

  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item:hover,
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link:hover {
    width: 200px !important;
  }

  .brand-minimized .main-sidebar .sidebar .nav-link.active {
    background-color: $navactive;
  }

  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item app-sidebar-nav-link-content,
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link app-sidebar-nav-link-content {
    padding-left: 15px;
  }
}

.width-custom:focus .spinner {
  display: block;
}

.spinner {
  font-size: 9px;
  margin-top: -34px;
  margin-right: 4px;
  width: 12px;
  color: #a0a0a0;
  height: 28px;
  float: right;

  // display: none;
  // .sp-arrow-up {
  // position: absolute;
  // }
  .sp-arrow-down {
    // margin-top: 11px;
    margin-left: 0px;
    // position: absolute;
  }
}

.clone {
  width: 35px;
  height: 35px;
  margin: auto;
  border: 1px solid $ltgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 18px;
  color: $ltgray !important;
  cursor: pointer;

  &:hover {
    color: $white !important;
    background-color: $ltgray;
    box-shadow: 0px 10px 15px $ltgray;
    text-decoration: none;
  }
}

.rename-link {
  cursor: pointer !important;
  // display: block;
  margin-left: 1px !important;
}

.recover-organization-list-select--list .single-select-mode .pure-checkbox input[type=checkbox]+label {
  text-transform: none !important;
}

.select--list{
  .dropdown-list{
   

    .arrow-down{ display: none !important;}
    .arrow-up{display: none !important;}
  }

}


.axis-color {
  color: #8a8a8a;
  font-size: 1.1em;
}

.axis-rotate-text {
  color: #b9b8b8;
  font-size: 1.1em;

  .tick {
    text {
      transform: rotate(-50deg) translate(-19px, -5px);
    }
  }
}

.axis-label {
  font-size: 1.2em;
}

.video-player-panel {
  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
  }
}

#playerButton {
    height: 30px;
    width: 200px;
    border-radius: 30px;
    font-size: 14px;
    color: $dkred;
    outline: none;
    transition: all 0.5s ease;
    margin-left: 0px;
    padding: 0px;
    margin-top:20px;
    display: flex;
    align-items: center;
    float: left;
    border: 0px;
    background-color: transparent;



    .player-icon {
        background: $dkred;
        color: $white;   
        border-radius: 30px;
        font-size: 14px;
        height: 30px;
        width: 30px;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;

        i{
          font-size: 14px !important;
        }
    }

    .player-btn-txt {
        padding: 0 0 0 20px;
        line-height: 0;
    }
}

#playerButton:hover {
  right: 0px;
  color: $dkred;
}

#playerButton:hover .player-icon{
    background-color: $white;
  color: $dkred;
}

#playerButton .player-icon {
  text-decoration: none;
  color: aliceblue;
  border-radius: 50%;
  padding: 15px;
  position: relative;
}


#playerButton:hover .player-icon::after {
  position: absolute;
  content: '';
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $btnshadow;
  margin: 0px;
  opacity: 1;
  transform-origin: center;
  animation: anim 2000ms linear infinite;
}

#playerButton:hover .player-icon::before {
  position: absolute;
  content: '';
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $btnshadow;
  margin: 0px;
  opacity: 0.8;
  transform-origin: center;
  animation: anim 2000ms linear infinite;
}

@keyframes anim {
  from {
      margin: 0px;
      opacity: 1;
  }

  to {
      margin: -15px;
      opacity: 0;
  }
}

#playerButton .player-btn-txt {
padding: 0 0 0 10px;
line-height: 0;
}

.location .form-group .form-control {
  background-color: transparent!important;
  padding-left: 0!important;
  padding-right: 0!important;
  border: none!important;
  border-bottom: 1px solid $dkgray !important;
  border-radius: 0!important;
  font-weight: 300!important;
  font-size: 14px;
}

.location .form-group .form-control:focus{ 
    outline: none;
    box-shadow:unset !important;
  }

.location .form-group .line {
  height: 1px;
  width: 0;
  position: absolute;
  background-color: #a9a9a9;
  display: inline-block;
  transition: width .3s ease-in-out;
  position: relative;
  top: -14px;
}

.location .form-group input:focus+.line, select:focus+.line {
width: 100%;
background-color: $navbg;
}

.goal-box .form-group .form-control {
  background-color: transparent!important;
  padding-left: 0!important;
  padding-right: 0!important;
  border: none!important;
  border-bottom: 1px solid $dkgray !important;
  border-radius: 0!important;
  font-weight: 300!important;
  font-size: 14px;
}

.goal-box .form-group .form-control:focus{ 
    outline: none;
    box-shadow:unset !important;
  }

.goal-box .form-group .line {
  height: 1px;
  width: 0;
  position: absolute;
  background-color: #a9a9a9;
  display: inline-block;
  transition: width .3s ease-in-out;
  position: relative;
  top: -14px;
}

.goal-box .form-group input:focus+.line, select:focus+.line {
width: 100%;
background-color: $navbg;
}



.goal-1 .goal-box .form-group .form-control {
  background-color: transparent!important;
  padding-left: 0!important;
  padding-right: 0!important;
  border: none!important;
  border-bottom: 1px solid $dkgray !important;
  border-radius: 0!important;
  font-weight: 300!important;
}

.goal-1 .goal-box .form-group .form-control:focus{ 
    outline: none;
    box-shadow:unset !important;
  }

  .goal-1 .goal-box .form-group .line {
  height: 1px;
  width: 0;
  position: absolute;
  background-color: #a9a9a9;
  display: inline-block;
  transition: width .3s ease-in-out;
  position: relative;
  top: unset;
}

.goal-1 .goal-box .form-group input:focus+.line, select:focus+.line {
width: 100%;
background-color: $navbg;
}

.custom-euro .width-custom{ background-color:white !important}

.location-delete-button{ 
   float:right; margin-top:-35px; color:$dkred!important}

.location-delete-button .fa-close{ font-size:24px; -webkit-text-fill-color: $dkred;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: $bggray;  -webkit-transition: 0.6s ease-out;
  -moz-transition:  0.6s ease-out;
  transition:  0.6s ease-out;}

  .location-delete-button .fa-close:hover{-webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    transform: rotateZ(360deg);}

    *::-webkit-scrollbar {
      width: 5px;
      height:5px
    }
    
    /* Track */
    *::-webkit-scrollbar-track {
      background: $inputbg; 
    }
     
    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: $plcolor; 
    }
    
    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background: $dkblack; 
    }

    .mat-sidenav-container .mat-drawer-inner-container::-webkit-scrollbar-track {
      background: transparent; 
    }


    .table .thead-dark th{ background-color: $bggray !important; color:$navbg; padding:1rem .65rem!important}

    .table tbody tr:hover td{
      color:$dkred;
    }

    .table tbody tr:hover th{
      color:$dkred;
    }

   .fa-arrows:before{
      content:'' !important;
      background-image: url(../assets/img/drag-svgrepo-com.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 25px;
    height: 25px;
    float: right;
    margin-top: 2px;
      
    }

    .manage-campany .form-group .form-control {
      background-color: transparent!important;
      padding-left: 0!important;
      padding-right: 0!important;
      border: none!important;
      border-bottom: 1px solid $dkgray !important;
      border-radius: 0!important;
      font-weight: 300!important;
    }
    
    .manage-campany .form-group .form-control:focus{ 
        outline: none;
        box-shadow:unset !important;
      }
    
    .manage-campany .form-group .line {
      height: 1px;
      width: 0;
      position: absolute;
      background-color: #a9a9a9;
      display: inline-block;
      transition: width .3s ease-in-out;
      position: relative;
      top: -14px;
    }
    
    .manage-campany .form-group input:focus+.line, select:focus+.line {
    width: 100%;
    background-color: $navbg;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      border-radius: 0%;
      outline: none;
      border: 1px solid $navactive;
      margin-right:10px
  }

  input[type='checkbox']:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      margin: 0% auto;
      border-radius:0%;
  }

input[type="checkbox"]:checked:before {
      background: $navactive;
      
  }
  
  input[type="checkbox"]:checked {
    border-color:$navactive;
  }

  .switch-3d .switch-slider{ border-radius: 3px;}

  .switch-3d .switch-slider:before{border-radius: 3px;}

 /* .dropdown-list{max-height: 112px;
    overflow: scroll; top:unset; padding-top: 0px;}*/

    .location-d-add .form-group .form-control {
      background-color: transparent!important;
      padding-left: 0!important;
      padding-right: 0!important;
      border: none!important;
      border-bottom: 1px solid $dkgray !important;
      border-radius: 0!important;
      font-weight: 300!important;
    }
    
    .location-d-add .form-group .form-control:focus{ 
        outline: none;
        box-shadow:unset !important;
      }
    
    .location-d-add .form-group .line {
      height: 1px;
      width: 0;
      position: absolute;
      background-color: #a9a9a9;
      display: inline-block;
      transition: width .3s ease-in-out;
      position: relative;
      top: -18px;
    }
    
    .location-d-add .form-group input:focus+.line, select:focus+.line {
    width: 100%;
    background-color: $navbg;
    }


    .editorDiv{ 
      select{ 
        border:0px; 
        border-bottom:1px solid $dkgray; 
        border-radius:0px; 
        padding-left:0px !important; 
        padding-right:0px !important
      }

  }

 .editorDiv {
 input{ 
   border:0px; 
   border-bottom:1px solid $dkgray;
   border-radius:0px; 
   padding-left:0px !important; 
   padding-right:0px !important
  }
}

.diff-checker .td-checkbox-container{ font-size:13px !important; line-height:36px !important; padding-left:30px !important}

.diff-checker .td-checkbox-container .checkmark{ height:20px !important; width:20px !important; 
  border:1px solid $navactive !important;}

.diff-checker .td-checkbox-container input:checked ~ .checkmark {
   background-color: $navactive !important;
   height:20px; width:20px; 
}

.diff-checker .td-checkbox-container .checkmark:after{
  border-width: 0 2px 2px 0 !important; 
  margin-left:2px; 
  margin-top:0px
}

.diff-checker #side-by-side{ 
  background-color:$navactive; 
  border:0px; 
  height:33px
}

.diff-checker #side-by-side.active{
  background-color:$navbg;
  &:focus{ outline:none}
}

.diff-checker #line-by-line{
  background-color:$navactive; 
  border:0px; 
  height:33px
}

.diff-checker #line-by-line.active{
  background-color:$navbg; 
  border:0px; 
  height:33px;

  &:focus{ outline:none}
}

.manage-cat-list .dropdown-list{ top:unset !important; padding-top:0px}

.manage-cat-list .list-area.single-select-mode .ng-star-inserted{ 
max-height:108px !important
}

.manage-co-select .dropdown-list{ top:unset !important; padding-top:0px}

.manage-co-select1 .dropdown-list{ top:unset !important; padding-top:0px}

.manage-co-select1 .list-area .ng-star-inserted{ 
  max-height:108px !important
  }

  .manage-co-select1 .arrow-down{ display:none}

  .filter-multi-list .dropdown-list{ top:unset !important; padding-top:0px}

  .navbar-brand-full{ height: 35px;}

  .navbar-nav .nav-link:hover{
    color:$dkred
  }

  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 10;
  }

  .mat-sidenav-content {
    height: 100%;
  }

  @media #{$mq-767-down} {
  .example-header .mob-header{
    min-width: 35px;
    display: flex !important;
    align-items: center !important;
    flex-wrap: unset;
  }

  .example-header .navbar-brand-full {
    height: 35px;
    width: 77px;
    object-fit: cover;
    object-position: left;
}

.example-header .brand-placeholder {
  width: 100px !important;
}

.mat-sidenav-content {
  width: 100%;
}

.footer{
  display: block !important;
  text-align: center;
}

}
  