// Variable overrides
// Variable overrides

//Color

$dkred: #ee313a;
$btnhovr: #c72f35;
$ltpurple: #beaeed;
$dkgray:#989bae;
$ltgray: #cccccc;
$bdcolor: #160f0f0d;
$btnshadow: rgba(224, 61, 68, .2);
$white: #fff;
$black:#000000;
$dkblack: #5f5f5f;
$inputbg: #efefef;
$plcolor: #9e9e9e;
$labelcolor: #574f4fde;
$boxshadow: rgba(0, 0, 0, 0.27);
$navbg: #171643;
$bggray: #fcfcfc;
$tdshadow:rgba(217, 209, 209, 0.2);
$green: #03c795;
$dkgreen: #00a076;
$lightgreen:#01b386;
$extralightgreen:#78db9f;
$grshadow:rgba(3, 199, 149, 0.11);
$nextbtn:#757575;
$overlay:rgba(0, 0, 0, 0.3);
$nextbtnshadow: rgba(0, 0, 0, .1);
$alertlight:#f7f7f7;
$trans:transparent;
$darkgreenhovr:#00a076;
$lightgray: #f2f2f2;
$lightyellow: #f8a912;
$dkyellow: #f79c0d;
$orange: #fe8900;
$drorange:#e97e00;
$blue: #3195f6;
$dkblue: #2b87d7;
$readonly: #f0f3f5;
$ltgrayshdw: rgba(22, 15, 15, .05);
$modal-back: rgba(0, 0, 0, .6);
$navactive:#3A448C;
$power:#73818f;
$blueshadow:rgba(58, 68, 140, 0.1);
$tabcolor:#eaeaed;
$tabborder:#d3d3d3;




// Media Queries
$mq-575-down: "only screen and (max-width:575px) and (orientation : landscape)";
$mq-620-up: "only screen and (min-width: 620px) and (orientation : landscape)";

$mq-767-down: "only screen and (max-width: 767px)";
$mq-768-down: "only screen and (min-width: 768px) and (max-width: 1199px) and (orientation : landscape)";

$mq-768-up: "only screen and (min-width: 768px) and (max-width: 1024px)";
$mq-767-up: "only screen and (max-width: 767px) and (orientation : landscape)";

$mq-991-up: "only screen and (max-width: 991.98px)";
$mq-1024-down: "only screen and (max-width: 1024px)";

$mq-1200-up: "only screen and (min-width: 1200px) and (max-width: 1366px) and (orientation : landscape)";
$mq-1300-up: "only screen and (max-width: 1300px)";
$mq-360-down: "only screen and (max-width: 360px)";

$mq-992-down: "only screen and (min-width: 992px)";
$mq-375-down: "only screen and (max-width: 375px)";

$mq-1366-up: "only screen and (max-width: 1366px)";
$mq-575-up: "only screen and (max-width: 575px)";

$mq-767-land: "only screen and (min-width: 360px) and (max-width: 767px) and (orientation : landscape)";
$mq-1824-down: "only screen and (max-width: 1824px)";