/* Morph Button: Default Styles */

.morph-button {
	position: relative;
	display: block;
	margin: 0 auto;
}

.morph-button > button {
	font-size: 20px;
    z-index: 1021;
    position: fixed;
    background-color: #78db9f;
    right: 0;
    top: 36%;
    box-shadow: 0px 10px 10px rgb(231 88 84 / 20%);
    border-radius: 3px 0px 0px 3px;
    width: 63px;
    height: 60px;
	border: none;
}

.morph-button.open > button {
	pointer-events: none;
}

.morph-content {
	pointer-events: none;
}

.morph-button.open .morph-content {
	pointer-events: auto;
}

/* Common styles for overlay and modal type (fixed morph) */
.morph-button-fixed,
.morph-button-fixed .morph-content {
	width: 300px;
	height: 60px;
	position: fixed;
	z-index: 99999;
}



.morph-button-fixed.open > button {
	opacity: 0;
	-webkit-transition: opacity 0.1s;
	transition: opacity 0.1s;
}

.morph-button-fixed .morph-content {
	position: fixed;
	/* z-index: 900; */
	opacity: 0;
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

.morph-button-fixed.open .morph-content {
	opacity: 1;
}

.morph-button-fixed .morph-content > div {
	visibility: hidden;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
	transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
}

.morph-button-fixed.open .morph-content > div {
	visibility: visible;
	height: auto;
	opacity: 1;
	-webkit-transition: opacity 0.3s 0.5s;
	transition: opacity 0.3s 0.5s;
}

.morph-button-fixed.active > button {
	z-index: 2000;
}

.morph-button-fixed.active .morph-content {
	z-index: 1900;
}

/* Transitions for overlay button and sidebar button */
.morph-button-overlay .morph-content,
.morph-button-sidebar .morph-content {
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
}

.morph-button-overlay.open .morph-content,
.morph-button-sidebar.open .morph-content {
	-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;	
}

/* Morph Button Style: Overlay */
.morph-button.morph-button-overlay {
	margin: 50px auto;
}

.morph-button-overlay .morph-content {
	overflow: hidden;
	background: #e85657;
}

.morph-button-overlay.open .morph-content {
	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;
}

/* Morph Button Style: Modal */
/* .morph-button-modal::before {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
	pointer-events: none;
} */

.morph-button-modal.open::before {
	opacity: 1;
	pointer-events: auto;
}

.morph-button-modal.active::before {
	/* z-index: 1800; */
}

.morph-button-modal .morph-content {
	overflow: hidden;
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

.morph-button-modal.open .morph-content {
	top: 50% !important;
	left: 50% !important;
	margin: -210px 0 0 -300px;
	width: 560px;
	height: 363px;
	-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

/* Colors and sizes for individual modals */

.morph-button-modal-3.open .morph-content {
    background-color: #fff;
	box-shadow: 0px 0px 60px rgba(0, 0, 0, .1);
}


.morph-button.morph-button-modal-3 {
	display: inline-block;
	margin: 10px 15px;
}

.morph-button-modal-3 > button,
.morph-button-modal-3 .morph-content {
	background-color: #e75854;
	color: #ffffff;
	
	outline: none;
}

.morph-button-modal-3.open .morph-content > div {
 	-webkit-transition: opacity 0.3s 0.3s;
	transition: opacity 0.3s 0.3s;
}

/* Media Queries */

@media screen and (max-width: 600px) {
	.morph-button-modal.open .morph-content {
		top: 100px !important;
		left: 0% !important;
		margin: 0;
		width: 100%;
		overflow-y: scroll;
		-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
		transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	}
}

@media screen and (max-width: 400px) {
	.morph-button-fixed,
	.morph-button-fixed .morph-content {
		width: 200px;
		height: 80px;
	}

	.morph-button-fixed > button {
		font-size: 75%;
	}

	.morph-button-sidebar > button {
		font-size: 1.6em;
	}

	.morph-button-inflow .morph-content .morph-clone {
		font-size: 0.9em;
	}

	.morph-button-modal-4,
	.morph-button-modal-4 .morph-content {
		width: 220px;
		height: 120px;
	}

	.morph-button-modal-4 > button {
		font-size: 100%;
		line-height: 50px;
	}

	.morph-button-modal-4 > button span {
		display: block;
	}

	.morph-button-modal-4 .morph-clone {
		right: 83px;
		bottom: 26px;
	}

	.morph-button-sidebar,
	.morph-button-sidebar .morph-content {
		width: 100% !important;
		height: 60px !important;
	}

	.morph-button-sidebar {
		bottom: 0px;
		left: 0px;
	}

	.morph-button-sidebar.open .morph-content {
		height: 100% !important;
	}
}