/* The radio-label */
.radio-label {
    display: block;
    position: relative;
    margin-left: 3px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: none;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
    margin: 2.5px;
}

/* On mouse-over, add a grey background color */
.radio-label:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-label input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-label input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-label .checkmark:after {
    top: 9px;
    left: 9px;
    border-radius: 50%;
    background: white;
}